.c12pksdh{position:absolute;top:50px;right:-10px;width:360px;height:640px;max-height:640px;z-index:1000;background:#FFFFFF;box-shadow:0px 1px 6px rgba(0,0,0,0.1), 2px 10px 48px 10px rgba(0,0,0,0.1);border-radius:24px;}
.h1o9bu7t{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding:24px 24px 15px 24px;}.h1o9bu7t h1{font-weight:600;font-size:20px;line-height:26px;color:#000000;text-transform:capitalize;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.cnw38ty{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:40px;height:40px;background:#F6F6F6;-webkit-backdrop-filter:blur(4px);backdrop-filter:blur(4px);border-radius:100px;cursor:pointer;border:none;}
.i6sq0ii{width:100%;height:247px;padding:8px 24px 24px 24px;border-bottom:1px solid #eeeeee;}
.i11xxs2m{width:312px;height:175.5px;background:#e6e6e4;border-radius:8px;background-image:var(--i11xxs2m-0);background-repeat:no-repeat;background-size:cover;background-position:inherit;}
.tkkiay2{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding:20px 0;}.tkkiay2 h2,.tkkiay2 span{font-weight:600;font-size:16px;line-height:20px;color:#1A1A1A;text-transform:capitalize;}.tkkiay2 h2{max-width:216px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.t1gh1nmq{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.t1gh1nmq span{margin-right:6px;}.t1gh1nmq img{cursor:pointer;}

.h1rdn7x1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding:16px 24px;}.h1rdn7x1 h3{font-weight:600;font-size:14px;line-height:16px;color:#8E8E93;text-transform:capitalize;}.h1rdn7x1 span{font-weight:600;}
.cdwayic{max-height:314px;overflow-y:scroll;border-radius:0 0 24px 24px;}.cdwayic::-webkit-scrollbar{display:none !important;}
.c1olbjck{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding:22px 24px;border-bottom:1px solid #eeeeee;cursor:pointer;}.c1olbjck h3,.c1olbjck h4{font-weight:600;font-size:16px;line-height:20px;color:#1a1a1a;text-transform:capitalize;}.c1olbjck span{font-size:12px;font-weight:600;}.c1olbjck > div{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.py4fm1c{overflow-y:scroll;border-radius:6px;max-height:561px;}.py4fm1c::-webkit-scrollbar{display:none !important;}
.py4fwol{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:0 24px;margin-bottom:12px;}.py4fwol > img{width:128px;height:72px;margin-right:12px;border-radius:6px;object-fit:cover;}.py4fwol > div{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.py4fwol > div h3{font-size:16px;font-weight:600;line-height:20px;text-transform:initial;max-width:135px;text-align:left;margin-right:16px;}
.pmfxcv9 .pose-preview-container{position:relative;top:inherit;bottom:inherit;left:inherit;right:inherit;width:100%;box-shadow:none;height:561px;}.pmfxcv9 .pose-preview-container .pose-preview-image{height:306px;background:#ffffff !important;}.pmfxcv9 .pose-preview-container .pose-preview-image img{border-radius:0 !important;height:306px;}.pmfxcv9 .pose-preview-container .pose-preview-image > mux-player{margin-top:0 !important;}.pmfxcv9 .pose-preview-container .pose-preview-image mux-player::part(video){position:relative !important;top:0 !important;bottom:0 !important;margin:auto !important;max-height:var(--pmfxcv9-0) !important;min-height:var(--pmfxcv9-1) !important;}.pmfxcv9 .pose-preview-container .display-overlay{display:none;}.pmfxcv9 .pose-preview-container .pose-preview-content{padding:24px 24px 0 24px;}.pmfxcv9 .pose-preview-container .pose-preview-content h1,.pmfxcv9 .pose-preview-container .pose-preview-content p{text-align:left;}.pmfxcv9 .pose-preview-container .pose-preview-content > div{height:173px;}.pmfxcv9 .pose-preview-container .buttons-container{display:none;}
.h1dvia5u{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding:24px 24px 15px 24px;}.h1dvia5u h1{font-weight:600;font-size:20px;line-height:26px;color:#000000;text-transform:capitalize;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.h1dvia5u > button:nth-child(1) > span{font-size:16px;}.h1dvia5u > button > span{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
