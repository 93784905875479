.c6p8yj4{background:rgba(0,0,0,0.4);position:fixed;top:0;left:0;z-index:10001;width:100%;height:100%;}.c6p8yj4 .modal-overlay{z-index:10000;}
.m1exf8of{position:absolute;z-index:10001;background:#1a1a1a;top:0;bottom:0;left:0;right:0;min-width:540px;max-width:540px;margin:auto;height:92.2%;max-height:720px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;border-radius:32px !important;}
.mjvtk03{width:100%;height:80px;border-radius:32px 32px 0 0;position:absolute;top:0;left:0px;z-index:1002;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.m1a07m7u{width:100%;height:80px;border-radius:32px 32px 0 0;padding:0 24px;position:absolute;left:0;right:0;z-index:10;background:#1a1a1a;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.b14q89n6{width:32px;height:32px;border-radius:34px;padding:10px;background:#333333;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;cursor:pointer;}.b14q89n6 > span{font-size:12px;font-weight:600;color:#8e8e93;height:12px;}
.c18xsvvk{border-radius:24px;margin-top:80px;overflow-y:auto;margin-bottom:24px;}@-moz-document url-prefix(){.c18xsvvk{-webkit-scrollbar-width:none;-moz-scrollbar-width:none;-ms-scrollbar-width:none;scrollbar-width:none;}}.c18xsvvk::-webkit-scrollbar{width:6px;}.c18xsvvk::-webkit-scrollbar-track{background:#1a1a1a;}.c18xsvvk::-webkit-scrollbar-thumb{background:#333333;border-radius:6px;}.c18xsvvk::-webkit-scrollbar-thumb:hover{background:#333333;}
.h1yiwgjz{padding:16px 24px;}.h1yiwgjz h2{text-transform:initial;color:#ffffff;font-size:32px;margin-bottom:12px;}.h1yiwgjz span{color:#8e8e93;font-size:16px;line-height:24px;}
.r1lgklsl{margin:0 auto;margin-top:24px;width:100%;max-width:361px;background:#333333;border-radius:16px;height:402px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding-bottom:16px;cursor:pointer;}
.i12sda4z{padding:16px;height:192px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}.i12sda4z > span{border-radius:100px;background:rgba(255,255,255,0.24);padding:2px 12px;color:#f6f6f6;font-size:14px;font-weight:500;line-height:20px;max-width:105px;}.i12sda4z h1{color:#ffffff;font-size:36px;font-weight:500;line-height:40px;-webkit-letter-spacing:-1.44px;-moz-letter-spacing:-1.44px;-ms-letter-spacing:-1.44px;letter-spacing:-1.44px;margin-top:8px;text-transform:capitalize;}.i12sda4z > div{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.i12sda4z > div h4{color:#ffffff;font-size:36px;font-weight:500;line-height:40px;-webkit-letter-spacing:-1.44px;-moz-letter-spacing:-1.44px;-ms-letter-spacing:-1.44px;letter-spacing:-1.44px;opacity:0.4;text-transform:lowercase;}.i12sda4z > div > div{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:48px;height:48px;padding:12px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:100px;background:rgba(255,255,255,0.24);-webkit-backdrop-filter:blur(24px);backdrop-filter:blur(24px);cursor:pointer;}.i12sda4z > div > div .icon-Play,.i12sda4z > div > div .icon-Success{color:#FFFFFF;}
.i9vck4l{width:345px;margin:0 auto;}.i9vck4l img{width:100%;border-radius:10px;height:100%;object-fit:cover;}
