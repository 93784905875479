.c1pjrcil{position:fixed;top:0;left:0;z-index:10001;width:100%;height:100%;}.c1pjrcil .modal-overlay{z-index:10000;}
.mciy96z{position:absolute;z-index:10001;background:#1a1a1a;top:0;bottom:0;left:0;right:0;min-width:540px;max-width:540px;margin:auto;height:92.2%;max-height:720px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;border-radius:32px !important;}
.m5jfqey{width:100%;height:80px;border-radius:32px 32px 0 0;padding:24px;position:absolute;top:0;left:0px;z-index:1002;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.mpdid07{width:100%;height:80px;border-radius:32px 32px 0 0;padding:0 24px;position:absolute;left:0;right:0;z-index:10;background:#1a1a1a;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.cdueea4{width:32px;height:32px;border-radius:34px;padding:10px;background:#333333;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;cursor:pointer;}.cdueea4 > span{font-size:12px;font-weight:600;color:#8e8e93;height:12px;}

.smim20m{padding:0 24px;}
.o15e4ooz{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-bottom:16px;width:100%;background:var(--o15e4ooz-0);border-radius:12px;padding:8px;cursor:pointer;}.o15e4ooz img{margin-right:24px;border-radius:12px;}.o15e4ooz > div{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.o15e4ooz > div h2{font-size:20px;color:#ffffff;margin-bottom:6px;text-transform:capitalize;}.o15e4ooz > div p{color:#8e8e93;font-size:14px;}
.t1ywtvj9{margin-top:72px;padding:24px 24px;height:112px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.t1ywtvj9 h1{color:#ffffff;font-size:32px;font-weight:600;line-height:40px;text-transform:capitalize;margin-bottom:8px;}
.m1x0996y{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;position:absolute;bottom:0;width:100%;height:122px;border-radius:0 0 32px 32px;z-index:1002;}.m1x0996y > button{background:#ffffff;padding:16px;z-index:10;width:100%;max-width:268px;margin:0 auto;}.m1x0996y > button:hover:active{background-color:#FFFFFF;}.m1x0996y > button:hover{opacity:1;}.m1x0996y > button > div > span{font-family:"Gilroy";font-size:18px;font-weight:600;line-height:24px;-webkit-letter-spacing:0px;-moz-letter-spacing:0px;-ms-letter-spacing:0px;letter-spacing:0px;color:#000000 !important;}
