.c1ewkuk{width:100%;max-width:100vw;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;padding:0 20px;margin:20px;}@media only screen and (min-width:1025px){.c1ewkuk{padding:0 40px;}}@media only screen and (min-width:1800px){.c1ewkuk{max-width:1440px;padding:0 0 0 40px;}}.c1ewkuk .modal-container{z-index:10002;}.c1ewkuk .modal-container .modal-overlay-default{opacity:0;}
.c75zj15{color:#1a1a1a;font-size:20px;height:58px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.cf2pxw0{color:#1a1a1a;font-size:16px;cursor:pointer;font-weight:500;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}.cf2pxw0:hover{font-weight:600;}
.c1nt66fa{border-radius:16px;background:linear-gradient( 180deg, rgba(255,255,255,0.08) 0%, rgba(157,157,157,0.24) 100% ), #000;position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;height:320px;padding:16px 16px 16px 32px;width:100%;cursor:pointer;}
.c1qa83wu{border-radius:8px;object-fit:cover;}
.iux673g{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:48px;height:48px;padding:12px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:100px;background:rgba(255,255,255,0.24);-webkit-backdrop-filter:blur(24px);backdrop-filter:blur(24px);cursor:pointer;position:absolute;bottom:32px;left:32px;}.iux673g .icon-Arrow--Right{color:#FFFFFF;font-size:16px;}
.i1a68cvk{color:#FFFFFF;font-size:16px;}
.iysg3zc{padding-top:8px;margin-top:8px;width:100%;}
.i1mi75bm{background:rgba(255,255,255,0.24);padding:2px 12px;border-radius:100px;color:#f6f6f6;}
.i1rhaoxz{font-size:36px;color:#ffffff;margin:16px 0;text-transform:capitalize;}
.izmn2rf{color:rgba(255,255,255,0.5);font-size:36px;}
.i18fn7vs{font-size:16px;text-transform:capitalize;font-weight:600;color:#505050;}
