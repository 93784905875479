.s13hyq3{width:100vw;padding-left:20px;position:relative;margin:20px 0;}.s13hyq3 .slick-list{padding:0 !important;overflow:hidden !important;}.s13hyq3 .slick-list::after,.s13hyq3 .slick-list::before{background:none !important;}.s13hyq3 .slick-track{-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;}.s13hyq3 .header-title-carousel{-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;max-width:100%;}.s13hyq3 .header-title-carousel .header-icon-carousel{margin-top:2px;margin-left:4px;}.s13hyq3 .tab-container:nth-child(1){display:var(--s13hyq3-1);cursor:pointer;}.s13hyq3 .tab-container:nth-child(1) h1,.s13hyq3 .tab-container:nth-child(1) .icon-Angle--Down{color:var(--s13hyq3-0);}.s13hyq3 .tab-container:nth-child(2){display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;min-width:-webkit-max-content;min-width:-moz-max-content;min-width:max-content;margin-left:var(--s13hyq3-2);color:var(--s13hyq3-3);font-size:18px;font-weight:600;line-height:24px;cursor:var(--s13hyq3-4);}.s13hyq3 .tab-container:nth-child(2) span{padding:4px;border-radius:4px;background:var(--s13hyq3-5);color:var(--s13hyq3-5);color:#FFFFFF;font-size:10px;font-weight:700;line-height:12px;-webkit-letter-spacing:0.2px;-moz-letter-spacing:0.2px;-ms-letter-spacing:0.2px;letter-spacing:0.2px;margin-left:4px;}.s13hyq3 .slick-track,.s13hyq3 .slick-slide{width:100%;visibility:var(--s13hyq3-8);}@media only screen and (min-width:1025px){.s13hyq3{padding-left:40px;}}@media only screen and (min-width:1800px){.s13hyq3{max-width:1440px;}.s13hyq3 .slick-dots{padding-right:0;}}
.cc73rdl{position:relative;min-width:340px;height:388px;background:linear-gradient(180deg,#494949 0%,#a3a3a3 100%);width:100%;border-radius:16px;padding:8px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;cursor:pointer;}.cc73rdl > span{position:absolute;top:16px;left:16px;font-size:20px;font-weight:600;line-height:26px;color:#FFFFFF;text-transform:capitalize;}
.u14f7vg{height:388px;border-radius:16px;margin-right:32px;cursor:pointer;background:linear-gradient( 180deg, #1a1a1a 0%, #1a1a1a 32.29%, #626262 55.21%, #e5e5e5 82.81%, #fff 100% );}
.u1qa6yfx{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;width:100%;height:100%;border-radius:16px;background:linear-gradient( 0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 100% ), linear-gradient( 180deg, rgba(255,255,255,0.08) 0%, rgba(157,157,157,0.24) 100% );}.u1qa6yfx > div{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;max-width:788px;}.u1qa6yfx > div img{width:430px;}.u1qa6yfx p{color:rgba(255,255,255,0.5);font-size:18px;font-weight:500;line-height:28px;}
.s8bzpft.cc73rdl{padding:0;background:linear-gradient( 180deg, #1a1a1a 0%, #1a1a1a 32.29%, #626262 55.21%, #e5e5e5 82.81%, #fff 100% );}
.s1tumtxr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;height:100%;background:linear-gradient( 180deg, rgba(255,255,255,0.08) 0%, rgba(157,157,157,0.24) 100% );border-radius:16px;padding:16px 16px 0 16px;}.s1tumtxr p{color:rgba(255,255,255,0.5);font-size:18px;font-weight:500;line-height:28px;}
.s3hf0ho{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;}.s3hf0ho h1{color:#FFFFFF;font-size:36px;font-weight:500;line-height:40px;-webkit-letter-spacing:-1.44px;-moz-letter-spacing:-1.44px;-ms-letter-spacing:-1.44px;letter-spacing:-1.44px;text-transform:initial;max-width:250px;margin-bottom:4px;}.s3hf0ho > div{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:48px;height:48px;border-radius:100px;background:rgba(255,255,255,0.24);-webkit-backdrop-filter:blur(24px);backdrop-filter:blur(24px);}.s3hf0ho > div .icon-Arrow--Right{font-size:20px;color:#FFFFFF;font-weight:500;}
.ut3w5cz{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;max-width:361px;margin-top:75px;}
.uzc5o7n.s3hf0ho{min-width:276px;}.uzc5o7n.s3hf0ho .icon-Arrow--Right{font-size:14px;color:#FFFFFF;font-weight:500;}
.cwid2gv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:24px;padding:8px 8px 0 8px;margin-bottom:12px;}.cwid2gv > span{color:#F6F6F6;font-size:14px;font-weight:500;line-height:20px;text-transform:capitalize;border-radius:100px;background:rgba(255,255,255,0.24);padding:2px 12px;margin-top:8px;height:24px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.cwid2gv .icon-More{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#FFFFFF;}
.cs1ktd4{padding-top:8px;}.cs1ktd4 > span{border-radius:100px;background:rgba(255,255,255,0.24);padding:2px 12px;color:#F6F6F6;font-size:14px;font-weight:500;line-height:20px;margin-left:8px;height:24px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;max-width:-webkit-max-content;max-width:-moz-max-content;max-width:max-content;}.cs1ktd4 h1{color:#FFFFFF;font-size:36px;font-weight:500;line-height:40px;-webkit-letter-spacing:-1.44px;-moz-letter-spacing:-1.44px;-ms-letter-spacing:-1.44px;letter-spacing:-1.44px;text-transform:capitalize;padding:0 8px;height:-webkit-max-content;height:-moz-max-content;height:max-content;overflow-y:scroll;width:290px;}.cs1ktd4 h1::-webkit-scrollbar{display:none !important;}
.c1tz5stf.cc73rdl h1{margin-top:8px;height:84px;}
.m16f2xof{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;padding:0 8px;position:absolute;bottom:200px;left:8px;right:8px;}.m16f2xof > span{color:#FFFFFF;font-size:36px;font-weight:500;line-height:40px;-webkit-letter-spacing:-1.44px;-moz-letter-spacing:-1.44px;-ms-letter-spacing:-1.44px;letter-spacing:-1.44px;opacity:0.4000000059604645;}.m16f2xof > div{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:48px;height:48px;padding:12px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:100px;background:rgba(255,255,255,0.24);-webkit-backdrop-filter:blur(24px);backdrop-filter:blur(24px);cursor:pointer;}.m16f2xof > div .icon-Play,.m16f2xof > div .icon-Success{color:#FFFFFF;}
.i1fngxng{width:324px;height:180px;border-radius:16px;position:absolute;bottom:8px;}.i1fngxng img{width:100%;height:100%;border-radius:16px;object-fit:cover;}
.m54kvah{position:absolute;right:16px;top:24px;}.m54kvah .icon-More{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#FFFFFF;}
.p7xkmrq{position:absolute;bottom:16px;left:16px;right:16px;}.p7xkmrq > div:first-child{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;margin-bottom:8px;}.p7xkmrq > div:first-child span{color:#FFFFFF;font-size:18px;font-weight:600;line-height:24px;}.p7xkmrq > div:last-child{width:100%;height:10px;background:rgba(255,255,255,0.24);border-radius:1000px;position:relative;}.p7xkmrq > div:last-child > div{position:absolute;height:8px;background:#FFFFFF;width:var(--p7xkmrq-2);border-radius:1000px;}
.s1wykf6w .skeleton-carousel .card-shine{width:340px;height:388px;background-size:340px 388px;}
.r75y5zx{position:absolute;top:0;bottom:0;left:0;right:0;margin:auto;border-radius:100px;background:rgba(255,255,255,0.24);-webkit-backdrop-filter:blur(24px);backdrop-filter:blur(24px);padding:8px 12px;color:#FFFFFF;font-size:16px;font-weight:600;line-height:20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;height:38px;width:103px;}.r75y5zx > span{font-size:20px;margin-right:8px;}
