.cmloiyu{background:rgba(0,0,0,0.4);position:fixed;top:0;left:0;z-index:10001;width:100%;height:100%;}.cmloiyu .modal-overlay{z-index:10000;}
.m52pnii{position:absolute;z-index:10001;background:#1a1a1a;top:0;bottom:0;left:0;right:0;min-width:540px;max-width:540px;margin:auto;height:92.2%;max-height:720px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;border-radius:32px !important;}
.mhjgixc{width:100%;height:80px;border-radius:32px 32px 0 0;padding:24px;position:absolute;top:-1px;left:0px;z-index:1005;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.mhjgixc h3{width:100%;max-width:300px;text-align:center;z-index:1000;text-transform:capitalize;color:rgba(255,255,255,1);text-transform:initial;font-size:16px;font-weight:600;line-height:20px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;}.mhjgixc .overlay-header{position:absolute;top:0;left:0;border-radius:24px 24px 0 0;height:80px;width:100%;z-index:1005;opacity:0.7;background:#000000;}
.ms2c89f{width:100%;height:80px;border-radius:32px 32px 0 0;padding:0 16px;position:absolute;left:0;right:0;z-index:10;background:#1a1a1a;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.ms2c89f h3{position:absolute;left:0;right:0;margin:0 auto;font-size:16px;}
.c25y510{width:32px;height:32px;border-radius:34px;padding:10px;background:#333333;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;cursor:pointer;}.c25y510 > span{font-size:12px;font-weight:600;color:#8e8e93;height:12px;}
.c1c0oyuz{overflow-y:var(--c1c0oyuz-0);max-height:100%;border-radius:24px;padding:var(--c1c0oyuz-1);max-width:var(--c1c0oyuz-2);margin:var(--c1c0oyuz-3);margin-top:64px;margin-bottom:24px;padding-bottom:90px;overflow-x:hidden;min-height:var(--c1c0oyuz-4);}@-moz-document url-prefix(){.c1c0oyuz{-webkit-scrollbar-width:none;-moz-scrollbar-width:none;-ms-scrollbar-width:none;scrollbar-width:none;}}.c1c0oyuz::-webkit-scrollbar{width:6px;}.c1c0oyuz::-webkit-scrollbar-track{background:#1a1a1a;}.c1c0oyuz::-webkit-scrollbar-thumb{background:#333333;border-radius:6px;}.c1c0oyuz::-webkit-scrollbar-thumb:hover{background:#333333;}
.t1l4su5i{padding:16px 0;margin-bottom:24px;}.t1l4su5i h1{color:#ffffff;font-size:32px;text-transform:capitalize;margin-bottom:8px;}.t1l4su5i p{color:#8e8e93;font-size:16px;}
.dg8xk8o{padding:16px;background:#333333;border-radius:16px;margin-bottom:16px;}.dg8xk8o h3{margin-bottom:16px;color:#8e8e93;font-size:16px;text-transform:capitalize;}
.d12zlzub{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.d3km3iu{width:40px;height:40px;border-radius:100px;background:rgba(255,255,255,0.1);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;color:#ffffff;font-size:16px;border:var(--d3km3iu-0);cursor:pointer;}
.t1g7ibvl{padding:16px;background:#333333;border-radius:16px;margin-bottom:16px;}.t1g7ibvl h3{margin-bottom:16px;color:#8e8e93;font-size:16px;text-transform:capitalize;}
.t10rotjh{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;gap:8px;}
.tmxc15i{width:50%;height:40px;border-radius:100px;background:rgba(255,255,255,0.1);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;color:#ffffff;font-size:16px;border:var(--tmxc15i-0);cursor:pointer;}
.l4utu3m{padding:16px;background:#333333;border-radius:16px;margin-bottom:16px;}.l4utu3m h3{margin-bottom:16px;color:#8e8e93;font-size:16px;text-transform:capitalize;}
.l1darw2j{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.l1darw2j h4{text-transform:lowercase;font-size:24px;color:#ffffff;}
.lxzxjkq{width:80px;height:40px;border-radius:100px;outline:none;border:none;background:rgba(255,255,255,0.1);color:#ffffff;font-size:24px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;cursor:pointer;}.lxzxjkq:disabled{cursor:no-drop;background:rgba(255,255,255,0.05);}
.m15ygomv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;position:absolute;bottom:0;width:100%;height:122px;border-radius:0 0 24px 24px;background:rgba(192,193,184,0.01);background:linear-gradient( 180deg, rgba(26,26,26,0) 0%, #000000 90%, #000000 100% );z-index:1002;}.m15ygomv .overlay-button{margin:auto;position:absolute;bottom:0;top:0;max-height:56px;margin:auto;left:16px;right:16px;z-index:9;background:#333333;max-width:268px;border-radius:30px;}.m15ygomv > button{background:#ffffff;padding:16px;position:absolute;bottom:0;top:0;max-height:56px;margin:auto;left:16px;right:16px;z-index:10;max-width:268px;}.m15ygomv > button:hover:active{background:#FFFFFF;}.m15ygomv > button:disabled{background-color:rgba(255,255,255,0.5) !important;}.m15ygomv > button:hover{opacity:1;}.m15ygomv > button > div > span{font-family:"Gilroy";font-size:18px;font-weight:600;line-height:24px;-webkit-letter-spacing:0px;-moz-letter-spacing:0px;-ms-letter-spacing:0px;letter-spacing:0px;color:#000000 !important;}.m15ygomv .overlay-footer{position:absolute;top:0;left:0;width:100%;height:100%;border-radius:0 0 24px 24px;}
.dbrmzee{position:absolute;top:0;bottom:0;left:0;right:0;z-index:1003;border-radius:24px;opacity:0.7;background:#000000;}
.s3dgr5g{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;padding:0;position:absolute;left:0;right:0;margin:0 auto;max-width:144px;}.s3dgr5g > div{width:144px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;gap:4px;}.s3dgr5g > div > div{width:33px;height:3px;border-radius:2px;background:#333333;}.s3dgr5g > div{height:40px;}.s3dgr5g > div > div{background:#D1D1D6;}.s3dgr5g > div > div:nth-child(1){background:var(--s3dgr5g-2);}.s3dgr5g > div > div:nth-child(2){background:var(--s3dgr5g-3);}.s3dgr5g > div > div:nth-child(3){background:var(--s3dgr5g-4);}.s3dgr5g > div > div:nth-child(4){background:var(--s3dgr5g-5);}
